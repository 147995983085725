import React, { useState } from 'react'
import "./loginin_signup.css"
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import {useNavigate} from "react-router-dom"

const LoginSignup = () => {
  const [myUsername, setUsername] = useState("");
  const [myPassword, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false)
  const navigate = useNavigate();
  const { backEndBaseURL } = require('../config');

  async function sendIt() {
    console.log(`${backEndBaseURL}/v1/login?username=${myUsername}&password=${myPassword}`);
    try {
      const result = await fetch(`${backEndBaseURL}/v1/login?username=${myUsername}&password=${myPassword}`, {
        method: 'GET'
      });
      console.log('the result', result);
        if (!result.ok) { // Check if the response status is not OK
            throw new Error(`HTTP error! status: ${result.status}`);
        }
        var response = await result.json();
        console.log(response[0]);
        if (response[0] === 'welcome Admin') {
            navigate("/adminFillOut");
        }
    } catch (e) {
        console.log('Fetch error:', e);
    }
  }

  // const handleLogin = () => {
  //   const user = users.find(user => user.username === username);
  //   if (user && user.password === password) {
  //     // Pass username in the state parameter of navigate
  //     navigate("./AdminFillOut", { state: { username: username } });
  //   } else {
  //     alert("Invalid username or password");
  //   }
  // };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handle_form_display = () => {

      return (
        <div className='form'>
          <p className='login'>Log In</p>
          <div className="data_entry">
            Username
            <input
              type="text"
              placeholder='Username'
              className='entry_box'
              value={myUsername}
              onChange={e => setUsername(e.target.value)}
            />
            Password
            <div className="password_container">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder='Password'
                className='entry_box'
                value={myPassword}
                onChange={e => setPassword(e.target.value)}
              />
              {passwordVisible ? <IoEyeSharp onClick={togglePasswordVisibility} /> : <IoEyeOffSharp onClick={togglePasswordVisibility} />}
            </div>
          </div>
          <button className='login_button' onClick={sendIt}>Log in</button>
          <div className="forgot_password">
          </div>
        </div>
      );
      

   
    // Include similar changes for sign-up and forgot password forms as needed.
  };

  return (
    <div className='container'>
      <div className='header'>
        <p className='app_title'>VisCAT Admin App</p>
        <div className='username'>
          {"Log In" }
        </div>
      </div>
      <div className='Login_Form'>
        <div className='login_img'></div>
        {handle_form_display()}
      </div>
    </div>
  )
}

export default LoginSignup;