import React, {useEffect, useState} from 'react';
import classCandidateFetch from './classCandidateFetch';
import './candidateInfoTable.css';
import RenderPath from '../components/RenderPath';


const CandidateInfoTable = ({input, disabled}) => {
    const [currData, setCurrData] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [selectedRowIdx, setSelectedRowIdx] = useState(null);


    useEffect(() => {  
        const fetchData = async ()=>{
            // only called when class changes
            setSelectedCandidate( null);
            setSelectedRowIdx( null );
            if (!disabled) {
                try {
                    const data = await classCandidateFetch(input);
                    if (data.length > 0) {
                        data.sort((candidate1, candidate2) => {
                            return candidate1.surName.localeCompare(candidate2.surName);
                        });
                    }
                    setCurrData(data);
                    setErrMsg("");
                } catch (error) {
                    console.console.error(error);
                    setCurrData([]);
                    setErrMsg(String(error));
                }
            }
        };
        fetchData();
    },[input, disabled]);

    const handleRowClick = (candidate, rowIdx) => {
        setSelectedCandidate(candidate);
        setSelectedRowIdx(rowIdx);
    };

    
    if (currData && !disabled){
        return (<div>
            <div className="candidateTableContainer">
                <div className="candidateTableWrapper">
                    <table className="candidateTable">
                        <thead>
                            <tr>
                                <th key="First Name">First Name</th>
                                <th key="Last Name">Last Name</th>
                                <th key="DoB">DoB</th>
                                <th key="Score">Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currData.map((row, rowIdx) => (
                                <tr
                                    key={rowIdx}
                                    onClick={() => handleRowClick(row, rowIdx)}
                                    className={selectedRowIdx === rowIdx ? 'selected' : ''} >
                                    <td key={"CandidateFirstName" + rowIdx}>{row.firstName}</td>
                                    <td key={"CandidateLastName" + rowIdx}>{row.surName}</td>
                                    <td key={"CandidateDoB" + rowIdx}>{new Date(row.DoB).toLocaleDateString()}</td>
                                    <td key={"CandidateScore" + rowIdx}>{row.cumulativeScore}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> 
            </div>
            <div className="candidateTableContainer">
                <div className="candidateTableWrapper">
                    <table className="candidateTable">
                        <tbody>
                            <div className="candidateDetails">
                                {selectedCandidate && (
                                    <div>
                                        <div>
                                            <RenderPath candidate={selectedCandidate}/>
                                        </div>
                                    </div>
                                )}
                                {!selectedCandidate && (
                                    <div>
                                        <p>No candidate selected.</p>
                                    </div>
                                )}
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>                  
        )
        
    }
    else if (errMsg){
        return (<div>
            <p>Issue with Fetching Candidate Data.</p>
        </div>);

    }else{
        return null;
    }
    
}

export default CandidateInfoTable;