import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
const { backEndBaseURL } = require('../config');

function formatEdit(response){
  const edit = Array.from(response);
  console.log('this is the: ', response);
  const array = edit.map(item => ({
    value: `${item.userId}`,
    label: `${item.firstName} ${item.surName}`
  }));
  console.log(array);
  return array;
}
async function retrieveData() {
  console.log(`${backEndBaseURL}/v1/admin`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/admin`, {
      method: 'GET'
    });
      if (!result.ok) { // Check if the response status is not OK
          throw new Error(`HTTP error! status: ${result.status}`);
      }
      var response = await result.json();
      if (response[0]) {
          const output = formatEdit(response);
          return new Promise(function(resolve, reject){
            resolve(output);
           });
      }
  } catch (e) {
      console.log('Fetch error:', e);
      return 0;
  }
}
const AdminDropDown = ({ placeholder, toggle, onChange }) => {
  const [options, setOptions] = useState([]);
  const [recentCreate, setRecentCreate] = useState(false);

  const fetchData = async () => {
    try {
      const response = await retrieveData();
      setOptions(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  },[]);

  async function handleCreate(){
    try{
      await fetchData();
      setRecentCreate(true);
    }
    catch(error){
      console.error('Error with data installment', error);
    }
    toggle()
  };
  // force selects the most recent creation
  function valselect(){
    if(recentCreate){
      const size = options.length - 1;
      onChange(options[size]);
      return options[size];
    }
  }
  // calls on change from parent and updates so others can be selected
  function changeEdit(selected){
    if (onChange) {
        onChange(selected);
      }
      setRecentCreate(false)
    };

  return (
    <div>
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        onChange={changeEdit}
        onCreateOption={handleCreate}
        value={valselect()}
        placeholder={placeholder}
      />
    </div>
  );
};

export default AdminDropDown;
