import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Modal from '../components/modal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const { backEndBaseURL } = require('../config');

function formatEdit(response){
  const edit = Array.from(response);
  console.log('this is the: ', response);
  const array = edit.map(item => ({
    value: `${item.userId}`,
    label: `${item.firstName} ${item.surName}`
  }));
  console.log(array);
  return array;
}
async function retrieveData() {
  console.log(`${backEndBaseURL}/v1/teacher`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/teacher`, {
      method: 'GET'
    });
      if (!result.ok) { // Check if the response status is not OK
          throw new Error(`HTTP error! status: ${result.status}`);
      }
      var response = await result.json();
      if (response[0]) {
          const output = formatEdit(response);
          return new Promise(function(resolve, reject){
            resolve(output);
           });
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw new Error(e);
  }
}


async function createTeacherFetch(firstName, lastName, DoB, email, phoneNumber){
  console.log(`${backEndBaseURL}/v1/teacher`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/teacher`, {
      method: 'POST',
      headers: { 
                'Content-Type': 'application/json'
               },
      body: JSON.stringify({
          "firstName": firstName,
          "lastName": lastName,
          "doB": DoB,
          "email": email,
          "phone": phoneNumber
        })
    });
      if (!result.ok) { // Check if response status is invalid 
          const errMsg = await result.text();
          throw new Error(`HTTP error! Status: ${result.status}\nIssue: ${errMsg}`);
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw e;
  }
}

const TeacherDropDown = ({ placeholder, onChange, teacherCreate=false }) => {
  const [options, setOptions] = useState([]);
  const [recentCreate, setRecentCreate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [DoB, setDoB] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [showCreateTeacher, setShowCreateTeacher] = useState(false);

  const fetchData = async () => {
    try {
      const response = await retrieveData();
      console.log("Teacher Info:", response);
      setOptions(response);
    } catch (error) {
      console.error('Fetch', error);
    }
  };

  useEffect(() => {  
    fetchData();
  },[]);

  async function handleCreate(inputValue){
    if (teacherCreate){
      setShowCreateTeacher(true);
    }
    
  };
  // force selects the most recent creation
  function valselect(){
    if(recentCreate){
      const size = options.length - 1;
      onChange(options[size]);
      return options[size];
    }
  }
  // calls on change from parent and updates so others can be selected
  function changeEdit(selected){
    if (onChange) {
        onChange(selected);
      }
      setRecentCreate(false);
    };

  return (
    <div>
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        onChange={changeEdit}
        onCreateOption={handleCreate}
        value = {valselect()}
        placeholder={placeholder}
      />
      <Modal isOpen={showCreateTeacher} close={() => setShowCreateTeacher(false)}>
          <div className="info_container">
          <div className="text_above_box">Email Address</div>
          <input 
                    type="text" 
                    placeholder="Contact" 
                    className="entry_box" 
                    value={email}
                    onChange={e => setEmail(e.target.value)}
              />
              <div className="text_above_box">First Name</div>
              <input 
                    type="text" 
                    placeholder="First Name" 
                    className="entry_box" 
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
              />
              <div className="text_above_box">Last Name</div>
              <input 
                    type="text" 
                    placeholder="Last Name" 
                    className="entry_box" 
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}

              />
              <div className="text_above_box">Date of Birth</div>
              <input 
                    type="date" 
                    className="entry_box" 
                    value={DoB}
                    onChange={e => setDoB(e.target.value)}
              />
              <div className="text_above_box">Phone No.</div>
              <PhoneInput 
                          country={'au'} 
                          value ={phoneNumber}
                          onChange={setPhoneNumber}
                          />
              <button onClick={async ()=>{
                console.log("Creating Teacher with input of", "FirstName:", firstName, 
                  "LastName:", lastName, "Email:", email, "DoB:", DoB, "phoneNumber:", phoneNumber);
                try{
                  await createTeacherFetch(firstName, lastName, DoB,email, phoneNumber);
                  await fetchData();
                  setRecentCreate(true);
                  setShowCreateTeacher(false);
                }catch (error){
                  console.log('Problem with data installment', error);
                  alert(String(error));
                }}
              }>Create Teacher</button>
          </div>
      </Modal>
    </div>
  );
};

export default TeacherDropDown;