
const { backEndBaseURL } = require('../config');

async function classCandidateFetch(classId){
    var strId = classId.toString(); 
    console.log(`${backEndBaseURL}/v1/classCandidates/${strId}`);
    try{
        const response = await fetch(`${backEndBaseURL}/v1/classCandidates/${strId}`,{
            method: "GET"
        });
        if (!response.ok) { // Check if the response status is not OK
            throw new Error(`HTTP error! status: ${response.status}`);
        };
        
        var data = await response.json();
        if (data === null){
            console.log("something broke");
        }
        
        if (data) {
            const arrayData = Array.from(data);
            // console.log("array data is:", arrayData);
            return new Promise(function(resolve, reject){
              resolve(arrayData);
            });
        }
    }catch (e){
        console.log('Fetch error:', e);
        throw e;
    }
}

export default classCandidateFetch;