import pdfMake from 'pdfmake'
import pdfFonts from "pdfmake/build/vfs_fonts";
import classCandidateFetch from './classCandidateFetch';
import logoUrl from "../Assets/viscat_logo.png";


// URL TODO: change variable to come from environ
const { frontEndCandidateTestURL } = require('../config');



// Converts provided image url to base64
async function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
    
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
    
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
    
        var dataURL = canvas.toDataURL("image/png");
    
        resolve(dataURL);
      };
    
      img.onerror = error => {
        reject(error);
      };
    
      img.src = url;
});}


// Alternative method
// async function getBase64(file) {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader()
//       reader.readAsDataURL(file)
//       reader.onload = () => {
//         resolve(reader.result)
//       }
//       reader.onerror = reject
//     })
// }

/**
 * Generate PDF for the class
 */

export async function generateClassPDF(schoolName, teacherName, className, classId){

    var data;
    try{
        data = await classCandidateFetch(classId);
        console.log("data is", data);
    }catch(error){
        console.log("Fetch", error);
        alert(`Problem in Fetch Candidates for PDF download.\n${String(error)}`);
        data = [];
        return;
    }

    if (data.length>0){
        data.sort((candidate1, candidate2)=>{
            if (candidate1.surName < candidate2.surName){
                return -1;
            }else if (candidate1.surName === candidate2.surName){
                return 0;
            }else{
                return 1;
            }
        })
    }

    // Candidate table
    const candidateTable = [["Candidate First Name", "Candidate Last Name"]];
    for (var i = 0; i < data.length; i++){
        candidateTable.push([data[i].firstName, data[i].surName]);
    }

    // Set up the table of candidates for the PDF 
    const qrSize = 227;

    // Set up contents of individual candidate pages for the PDF
    const pages = []
    var dataElement;
    var currCandidateName;
    var currLink;
    for (i = 0; i<data.length; i++){
        currCandidateName = data[i].firstName.concat(" ", data[i].surName);
        currLink = frontEndCandidateTestURL.concat("/?testUID=",data[i].testUID);
        console.log(`qr code for "${data[i].surName}" is "${currLink}"`);
        let dobDate = new Date(data[i].DoB);
        dataElement = {
            columns:[
                {stack: [
                    {image:"logo",width: 40},
                    {text:"\n", fontSize: 10},
                    {text:"Candidate Name: ".concat(currCandidateName),
                        bold: true, fontSize: 20},
                    {text:"\n", fontSize: 10},
                    {text:"Date of Birth: ".concat(dobDate.toLocaleDateString()),
                        bold: true, fontSize: 20},
                    {text:"\n", fontSize: 10},
                    {text:"Class: ".concat(className), 
                        bold: true, fontSize: 20}],
                },
                {stack:[{text:"\n", fontSize: 50},
                    {qr: currLink, fit: qrSize, alignment: 'center'}]}
            ]}
        
        if (i !== (data.length -1)){
            dataElement.pageBreak = 'after';
        }
        pages.push(dataElement);
    }


    // Get Current Time and Format it properly
    var currDateTime = new Date();
    var currDate = currDateTime.toDateString();
    var currTime = currDateTime.toTimeString().split(" ")[0];  
    var twelveHrTime;
    var currSplitTime = currTime.split(":");  // Split into hr, min, sec
    if (parseInt(currSplitTime[0],10) >= 12){
        twelveHrTime = currSplitTime[0].concat(":", currSplitTime[1]," PM");
    }else{
        twelveHrTime = currSplitTime[0].concat(":", currSplitTime[1]," AM");
    }
    var dateTimeGenerated = currDate.concat(" ", twelveHrTime);

    
    // Define the contents of PDF file and their alignment & properties
    var docDefinition ={
        pageOrientation: 'landscape',
        footer: function(currentPage, pageCount){
            return {
                columns:[
                    {text: "Teacher Name: ".concat(teacherName), 
                        alignment: 'center'},
                    {text: "Class: ".concat(className), alignment: 'center'},
                    {text:"Generated On: \n".concat(dateTimeGenerated), alignment: 'center'},
                    {text: "Page " + currentPage.toString() + ' of ' + 
                        pageCount, alignment: 'center' }
                ]
            };
        },
        content:[
            {columns:[{image:"logo",width:40},
                {text:"Vis-CAT Candidate QR Codes", fontSize:40, alignment: 'center'}]},
            {text:"\n", fontSize:10},
            {
                stack:[
                    {text:"School Name: ".concat(schoolName),
                        bold: true, fontSize: 16},
                    {text:"Teacher Name: ".concat(teacherName),
                            bold: true, fontSize: 16},
                    {text:"Class: ".concat(className),
                        bold: true, fontSize: 16},
                    {table:{widths: ['*', '*'], 
                     headerRows: 1, body:candidateTable}, 
                     margin: [0, 10, 0, 0]},
                ],
                alignment: 'center',
                pageBreak: 'after'
            },
            {
                stack: pages
            }  
        ],
        images:{
            logo: await getBase64ImageFromURL(logoUrl)
        }
    };

    const fileName = className.concat(" Candidate QR Codes.pdf");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).download(fileName);
}

