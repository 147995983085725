import React, { useState, useEffect} from 'react';
import './AdminFillOut.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Dropdown from '../components/Dropdown';
import SearchableDropdownSchool from '../components/searchable_dropdown';
import SearchableDropdownClass from '../components/searchable_dropdown_class';
import TeacherDropDown from '../components/searchable_dropdown_teacher';
import Modal from '../components/modal';
import {generateClassPDF} from '../components/PdfGenerator';
import * as XLSX from 'xlsx';
import CandidateInfoTable from '../components/candidateInfoTable';

const { backEndBaseURL } = require('../config');

const AdminFillOut = () => {
    const [menuOption, setMenuOption] = useState(3);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [selected, setSelected] = useState(3);
    const [excelData, setExcelData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [adminSelected, setAdminSelected] = useState(false);
    const [schoolSelected, setSchoolSelected] = useState(false);
    const [teacherSelected, setTeacherSelected] = useState(false);
    const [classSelected, setClassSelected] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [DoB, setDoB] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [selectedSchool, setSelectedSchool] = useState();
    const [selectedClass, setSelectedClass] = useState();
    const [selectedTeacher, setSelectedTeacher] = useState();
    const [schoolName, setSchoolName] = useState('');
    const [className, setClassName] = useState('');
    const [teacherName, setTeacherName] = useState('');




//resets selected variables
function resetSelected(){
    setSchoolSelected(false);
    setTeacherSelected(false);
    setClassSelected(false);
    setSelectedSchool();
    setSelectedClass();
    setSelectedTeacher();
    setTeacherName('');
    setSchoolName('');
    setClassName('');
}
// Function to handle school selection
const handleSchoolSelect = (selectSchool) => {
    setSchoolSelected(!!selectSchool); // Set to true if a school is selected, otherwise false
    console.log('selected school', selectSchool);
    if(selectSchool != null){
        console.log(selectSchool);
        setSelectedSchool(selectSchool.value);
        setSchoolName(selectSchool.label);
    }
    else{
        setSelectedSchool();
        setSchoolName('');
    }
};

// variable updates confirmed
useEffect(() => {
    console.log('Selected School has changed:', selectedSchool);
  }, [selectedSchool]);
  useEffect(() => {
    console.log('Selected Class has changed:', selectedClass);
  }, [selectedClass]);
  useEffect(() => {
    console.log('Selected Teacher has changed:', selectedTeacher);
  }, [selectedTeacher]);

// Function to handle Admin selection
const handleAdminSelect = (selectAdmin) => {
    setAdminSelected(!!selectAdmin); // Set to true if a school is selected, otherwise false
    console.log('selected Admin', selectAdmin);
};

const handleClassSelect = (selectClass) => {
    console.log("select Class BE ", selectClass);
    setClassSelected(!!selectClass); // Set to true if a class is selected, otherwise false
    if(selectClass != null){
        console.log(selectClass.value);
        setSelectedClass(selectClass.value);
        setClassName(selectClass.label);
    }
    else{
        console.log("emptied");
        setSelectedClass();
        console.log("selectedClass beee", selectedClass)
        setClassName('');
    }
};

const handleTeacherSelect = (selectTeacher) =>{
    setTeacherSelected(!!selectTeacher);
    console.log('the values are');
    if(selectTeacher != null){
        console.log(selectTeacher.value);
        setSelectedTeacher(selectTeacher.value);
        setTeacherName(selectTeacher.label);
    }
    else{
        setSelectedTeacher();
        setTeacherName('');
    }    
}

function convertDate(date){
    const parts = date.split('/');
    return `${[parts[2]]}-${parts[1]}-${parts[0]}`;
}


function createCandidates(){
    // Because template has weird naming convention
    const classPayload = excelData.map(item => {
        return {
            firstName: item.FirstName.trim(), lastName: item.LastName.trim(), gender: item.Gender, doB: item['Date of Birth']
        };
    });
    // actual fetch
    createCandidateFetch(classPayload)
}
async function createCandidateFetch(body){
    console.log(body);
    console.log(`${backEndBaseURL}/v1/candidate`, selectedClass, body);
    try {
      const result = await fetch(`${backEndBaseURL}/v1/candidate`, {
        method: 'POST',
        headers: { 
                  'Content-Type': 'application/json'
                 },
        body: JSON.stringify({
            "ClassID": Number(selectedClass),
            "Candidates": body
          })
      });
        if (!result.ok) { // Check if response status is invalid
            const errMsg = await result.text();
            throw new Error(`HTTP error! Status: ${result.status} \nIssue: ${errMsg}`); 
        }
        else{
            setShowCreateForm(false);
            alert("Upload of Candidates is Successful");
            console.log(result.status);
        }
    } catch (e) {
        alert( String(e));
        console.log('Fetch error:', e);
    }
}

async function createAdminFetch(){
    console.log(`${backEndBaseURL}/v1/admin`);
    try {
      const result = await fetch(`${backEndBaseURL}/v1/admin`, {
        method: 'POST',
        headers: { 
                  'Content-Type': 'application/json'
                 },
        body: JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "doB": DoB,
            "email": email,
            "phone": phoneNumber
          })
      });
        if (!result.ok) { // Check if response status is invalid 
            throw new Error(`HTTP error! status: ${result.status}`);
        }
        else{
            setShowCreateForm(false);
        }
    } catch (e) {
        console.log('Fetch error:', e);
    }
}

    const handleMenuSelection = () => {

        if (menuOption === 1) {
            const handleCreateNewAdmin = () => {
                setShowCreateForm(true); // This function will be called when a new admin option is created
            };

            return (
                <div >
                    {!showCreateForm && (
                        <div className="Management_container">
                            <div className="Management_msg">Select Admin User to Force Password Change</div>
                            <Dropdown placeholder={"Select Admin"} toggle={handleCreateNewAdmin} onChange={handleAdminSelect}/>
                            <button className="create_button">Force change password</button>
                        </div>
                    )}

                    <Modal isOpen={showCreateForm} close={() => setShowCreateForm(false)}>
                        <div className="info_container">
                            <div className="text_above_box">Email Address</div>
                            <input 
                                  type="text" 
                                  placeholder="Username" 
                                  className="entry_box" 
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                            />
                            <div className="text_above_box">First Name</div>
                            <input 
                                  type="text" 
                                  placeholder="First Name" 
                                  className="entry_box" 
                                  value={firstName}
                                  onChange={e => setFirstName(e.target.value)}
                            />
                            <div className="text_above_box">Last Name</div>
                            <input 
                                  type="text" 
                                  placeholder="Last Name" 
                                  className="entry_box" 
                                  value={lastName}
                                  onChange={e => setLastName(e.target.value)}

                            />
                            <div className="text_above_box">Date of Birth</div>
                            <input 
                                  type="date" 
                                  className="entry_box" 
                                  value={DoB}
                                  onChange={e => setDoB(e.target.value)}
                            />
                            <div className="text_above_box">Phone No.</div>
                            <PhoneInput 
                                       country={'au'} 
                                       value ={phoneNumber}
                                       onChange={setPhoneNumber}
                                       />
                            <button onClick={createAdminFetch}>Create Admin User</button>
                        </div>
                    </Modal>
                </div>
            );
        } 
        else if (menuOption === 3) {
            return (
                <div className="Management_container">
                    <div className="Management_msg">Select School</div>
                    <div><SearchableDropdownSchool onChange={handleSchoolSelect}/></div>
                    <div className="Management_msg">Select Teacher</div>
                    <TeacherDropDown onChange={handleTeacherSelect} teacherCreate={true}/>
                    <div className="Management_msg">Select Class</div>
                    <div><SearchableDropdownClass disabled={!(schoolSelected && teacherSelected)} onChange= {handleClassSelect} input={[selectedSchool, selectedTeacher, 1]}/></div>
                </div>
            );
        } else if (menuOption === 4) {
            const sampleCandidateData = '../Candidate_upload_template.xlsx';
            const downloadFileAtURL = (url) => {
                const fileName = url.split("/").pop();
                const aTag = document.createElement("a");
                aTag.href = url;
                aTag.setAttribute("download", fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            };

            const isValidDate = (dateString) => {
                const date = new Date(dateString);
                return !isNaN(date.getTime());
            };

            const handleFileUpload = (e) => {
                setExcelData([]); // Clear previous data
                setErrorMessage(''); // Clear previous error message
            
                const reader = new FileReader();
                
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'array', cellDates:true, cellNF: false });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const parsedData = XLSX.utils.sheet_to_json(sheet);
                    console.log("currData:", parsedData);
            
                    // Validation checks
                    if (parsedData.length === 0){
                        setErrorMessage('The uploaded file must Contain Candidate Data');
                        return;
                    }else if (Object.keys(parsedData[0]).length !== 4) {
                        setErrorMessage('The uploaded file must have exactly 4 columns.');
                        return;
                    }

                    const keys = Object.keys(parsedData[0]);
                    if (keys[0] !== "FirstName"){
                        setErrorMessage("The First Column's Header must be called FirstName");
                        return;
                    }else if(keys[1] !== "LastName"){
                        setErrorMessage("The Second Column's Header must be called LastName");
                    }else if(keys[2] !== "Date of Birth"){
                        setErrorMessage("The Third Column's Header must be called Date of Birth");
                    }else if (keys[3] !== "Gender"){
                        setErrorMessage("The Fourth Column's Header must be called Gender");
                    }

            
                    const valid = parsedData.every((row) => {
                        const keys = Object.keys(row);
                        return (
                            keys.length === 4 &&
                            isValidDate(row[keys[2]]) &&
                            ['M', 'F', 'NB'].includes(row[keys[3]])
                        );
                    });
            
                    if (!valid) {
                        setErrorMessage('The 3rd column must be a date and the 4th column must be "M", "F", or "NB".');
                        return;
                    }
            
                    // Convert date column to readable format
                    const formattedData = parsedData.map(row => {
                        const keys = Object.keys(row);
                        row[keys[2]] = new Date(row[keys[2]]).toLocaleDateString(); // Format the date
                        row[keys[2]] = convertDate(row[keys[2]]);
                        console.log('row', row);
                        //createCandidateFetch(row);
                        return row;
                    });
            
                    setExcelData(formattedData);
                    setErrorMessage('');
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                console.log('DATA excel',excelData);
            };
            

            return (
                <div className="Management_container">
                    <button onClick={() => { downloadFileAtURL(sampleCandidateData) }} className="sample_file">Download template Excel file</button>
                    <div className="Management_msg">Select School</div>
                    <div><SearchableDropdownSchool onChange={handleSchoolSelect} /></div>
                    <div className="Management_msg">Select Teacher</div>
                    <div><TeacherDropDown onChange={handleTeacherSelect}/></div>
                    <div className="Management_msg" >Select Class</div>
                    <div><SearchableDropdownClass disabled={!(schoolSelected && teacherSelected)} onChange={handleClassSelect} input={[selectedSchool, selectedTeacher, -1]}/></div>
                    

                    <div className="import_excel">
                        <form className="form-grp">
                            <input type="file" required className="form-control" accept=".xlsx, .xls" onChange={handleFileUpload} disabled = {!classSelected} />
                        </form>

                        {errorMessage && <div className="error_message">{errorMessage}</div>}

                        {excelData.length > 0 && (
                            <div className="table_container">
                                <table>
                                    <thead>
                                        <tr>
                                            {Object.keys(excelData[0]).map((key) => (
                                                <th key={key}>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {excelData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {Object.values(row).map((value, cellIndex) => (
                                                    <td key={cellIndex}>
                                                        {cellIndex === 2 ? new Date(value).toLocaleDateString() : value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        )}
                    </div>
                    <button className="create_button" disabled = {!classSelected} onClick={createCandidates}>Upload Candidates</button>
                </div>
            );
        }
        else if (menuOption === 5) {
            return(
                <div className="Management_container">
                    <div className="Management_msg">Select School</div>
                    <div><SearchableDropdownSchool onChange={handleSchoolSelect} /></div>
                    <div className="Management_msg">Select Teacher</div>
                    <div><TeacherDropDown onChange={handleTeacherSelect}/></div>
                    <div className="Management_msg" >Select Class</div>
                    <div><SearchableDropdownClass disabled={!(schoolSelected && teacherSelected)} onChange={handleClassSelect} input={[selectedSchool, selectedTeacher,-1]}/></div>
                    <div><CandidateInfoTable disabled={!(classSelected && schoolSelected && teacherSelected)} 
                           input={selectedClass}/></div>
                    <button className="create_button" disabled = {!(classSelected && schoolSelected && teacherSelected)} 
                      onClick={()=>generateClassPDF(schoolName, teacherName, className, selectedClass)}>
                        Generate Class QR code pdf
                    </button>
                </div>
            );
        }
        else if (menuOption === 6) {
            return(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '47.5%' }}>
                        <div className="Management_container">
                            <div className="Management_msg">Select School</div>
                            <div><SearchableDropdownSchool onChange={handleSchoolSelect} /></div>
                            <div className="Management_msg">Select Teacher</div>
                            <div><TeacherDropDown onChange={handleTeacherSelect}/></div>
                            <div className="Management_msg">Select Class</div>
                            <div><SearchableDropdownClass disabled={!(schoolSelected && teacherSelected)} onChange={handleClassSelect} input={[selectedSchool, selectedTeacher,-1]}/></div>
                        </div>
                    </div>
                    <div style={{ width: '47.5%' }}>
                        <div className="Management_container">
                            <CandidateInfoTable 
                                disabled={!(classSelected && schoolSelected && teacherSelected)} 
                                input={selectedClass}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="Admin_container">
            <div className="menu">
                <div className="menu_title">Admin App</div>
                <div className="menu_items">
                    <button className={selected === 3 ? 'menu_option_selected' : 'menu_option'} onClick={() => { setMenuOption(3); setSelected(3); resetSelected();}}>Manage School, Class, Teacher</button>
                    <button className={selected === 4 ? 'menu_option_selected' : 'menu_option'} onClick={() => { setMenuOption(4); setSelected(4); resetSelected();}}>Import Candidates</button>
                    <button className={selected === 5 ? 'menu_option_selected' : 'menu_option'} onClick={() => { setMenuOption(5); setSelected(5); resetSelected();}}>Generate QR Codes</button>
                    <button className={selected === 1 ? 'menu_option_selected' : 'menu_option'} onClick={() => { setMenuOption(1); setSelected(1); resetSelected();}}>Manage Admin User</button>
                    <button className={selected === 6 ? 'menu_option_selected' : 'menu_option'} onClick={() => { setMenuOption(6); setSelected(6); resetSelected();}}>Candidate Reporting</button>
                </div>
            </div>
            <div className={ "content_renderer"}>
                {handleMenuSelection()}
            </div>
        </div>
    );
};

export default AdminFillOut;
