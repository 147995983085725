import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
const { backEndBaseURL } = require('../config');

function formatEdit(response){
  const edit = Array.from(response);
  console.log('this is the: ', response);
  const array = edit.map(item => ({
    value: `${item.schoolId}`,
    label: `${item.schoolName}`
  }));
  console.log(array);
  return array;
}
async function retrieveData() {
  console.log(`${backEndBaseURL}/v1/school`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/school`, {
      method: 'GET'
    });
      if (!result.ok) { // Check if the response status is not OK
          throw new Error(`HTTP error! status: ${result.status}`);
      }
      var response = await result.json();
      if (response[0]) {
          const output = formatEdit(response);
          return new Promise(function(resolve, reject){
            resolve(output);
           });
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw new Error(e);
  }
}

async function createSchoolFetch(schoolName){
  console.log(`${backEndBaseURL}/v1/school`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/school`, {
      method: 'POST',
      headers: { 
                'Content-Type': 'application/json'
               },
      body: JSON.stringify({
          "name": schoolName
        })
    });
      if (!result.ok) { // Check if response status is invalid 
          const errMsg = result.text();
          throw new Error(`Status: ${result.status} \nIssue: ${errMsg}`);
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw e;
  }
}

const SearchableDropdownSchool = ({ placeholder, onChange }) => {
  const [options, setOptions] = useState([]);
  const [recentCreate, setRecentCreate] = useState(false);
  const [recentSchoolName, setRecentSchoolName] = useState("");

  const fetchData = async () => {
    try {
      const response = await retrieveData();
      setOptions(response);
    } catch (error) {
      console.error('Fetch', error);
    }
  };

  useEffect(() => {  
    fetchData();
  },[]);
  // creates new school
  async function handleCreate(inputValue){
    try{
      await createSchoolFetch(inputValue);
      await fetchData(); 
      setRecentSchoolName(inputValue);
      setRecentCreate(true);
    }
    catch(error){
      console.error('Error with data installment', error);
      alert(`Problem with School Creation. ${error}`);
    }
  };
  
  // force selects the most recent creation
  function valselect(){
    if(recentCreate){
      if(recentCreate){
        for (var i=0; i<options.length; i++){
          if (options[i].label === recentSchoolName){
            onChange(options[i]);
            return options[i];
          }
        }
      }
    }
  }
  // calls on change from parent and updates so others can be selected
  function changeEdit(selected){
    if (onChange) {
        onChange(selected);
      }
      setRecentCreate(false);
    };

  return (
    <div>
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        onChange={changeEdit}
        onCreateOption={handleCreate}
        value = {valselect()}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchableDropdownSchool;
