import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Circle, Line } from 'react-konva';
import './RenderPath.css';

const { backEndBaseURL } = require('../config');

async function getDirectCopyTestStats(testUID){ 

  const levelsToGet = [ `Direct Copy`, `Horizontal Flip`, `Vertical Flip`];
  var retval = [];

    try{
      for( let i=0; i<levelsToGet.length; i++ ){
        const response = await fetch(`${backEndBaseURL}/v1/candidateTest/${testUID}/stats?level=${levelsToGet[i]}`,{
          method: "GET"
        });

        if (!response.ok) { 
            throw new Error(`HTTP error! status: ${response.status}`);
        };
        
        var stats = await response.json();
        var path = [];
        var undos = 0;
        var restarts = 0;

        for( var j=0; j<stats.length; j++ ){
          if( stats[j].statName.startsWith('dot-') ){
            const nextPath = parseInt(stats[j].statName.split('-')[1], 10);
            if( nextPath !== path[path.length-1]){ 
              path.push(nextPath);
            }
          }else if( stats[j].statName === 'undo' ){
            path.pop();
            undos++;
          }else if( stats[j].statName === 'restart' ){
            path = [];
            restarts++;
          }
        }

        // var retval = {
        //   undoCount : undos,
        //   restartCount : restarts,
        //   dots: path
        // };

        retval.push( {level: levelsToGet[i],
                      stats: {
                        undoCount : undos,
                        restartCount : restarts,
                        dots: path
                      }
                    });
        }

      console.log( retval );
      
      return retval;
    }
    catch (e){
        console.log('Fetch error:', e);
        throw e;
    }
}

const RenderPath = ({ candidate }) => {
  const stageRef = useRef(null);
  const gridSize = 4; 
  const circleRadius = 10;
  const padding = 25;

  const width = (circleRadius * 2 + padding) * gridSize + padding;
  const height = (circleRadius * 2 + padding) * gridSize + padding;
  const [curPath, setCurPath] = useState(null);

  useEffect(() => {  
    const fetchPath = async ()=>{
               
        if (candidate) {
            try {
                const data = await getDirectCopyTestStats(candidate.testUID);
                setCurPath( data );
            } catch (error) {
                console.error(error);
                setCurPath( null );
            }
        }
    };
    fetchPath();
  },[candidate]);

  if( curPath === undefined || curPath === null || curPath[0].stats.dots.length === 0 ){
    return (
      <div>
        <pre><strong>No test data for selected candidate</strong></pre>
      </div> 
    );
  }

  const circles = [];
  for (let row = 0; row < gridSize; row++) {
    for (let col = 0; col < gridSize; col++) {
      const x = padding + circleRadius + col * (2 * circleRadius + padding);
      const y = padding + circleRadius + row * (2 * circleRadius + padding);
      circles.push({ x, y });
    }
  }

  const getIntersectionPoint = (start, end, radius) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const angle = Math.atan2(dy, dx);

    return {
      x: end.x - radius * Math.cos(angle),
      y: end.y - radius * Math.sin(angle),
    };
  };

  const getArrowPoints = (start, end, arrowLength = 6, arrowWidth = 4) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const angle = Math.atan2(dy, dx);

    const arrowPoint1 = {
      x: end.x - arrowLength * Math.cos(angle - Math.PI / 6),
      y: end.y - arrowLength * Math.sin(angle - Math.PI / 6),
    };

    const arrowPoint2 = {
      x: end.x - arrowLength * Math.cos(angle + Math.PI / 6),
      y: end.y - arrowLength * Math.sin(angle + Math.PI / 6),
    };

    return [end.x, end.y, arrowPoint1.x, arrowPoint1.y, arrowPoint2.x, arrowPoint2.y];
  };

  return (
    <div>
      {curPath.map((levelData, index) => (
        <div key={index}>
          <pre align="left">
            <strong>{levelData.level}:</strong> Undo count: {levelData.stats.undoCount}, Restart count: {levelData.stats.restartCount}
          </pre>
          <Stage width={width} height={height} ref={stageRef}>
            <Layer>
              {circles.map((circle, index) => (
                <Circle key={index} x={circle.x} y={circle.y} radius={circleRadius} fill="black" />
              ))}
              
              {levelData.stats.dots.slice(1).map((circleIndex, i) => {
                const startCircle = circles[levelData.stats.dots[i]];
                const endCircle = circles[circleIndex];

                const start = getIntersectionPoint(endCircle, startCircle, circleRadius);
                const end = getIntersectionPoint(startCircle, endCircle, circleRadius);

                const linePoints = [start.x, start.y, end.x, end.y];
                const arrowPoints = getArrowPoints(start, end);

                return (
                  <React.Fragment key={i}>
                    <Line
                      points={linePoints}
                      stroke="blue"
                      strokeWidth={2}
                      tension={0}
                      lineCap="round"
                      lineJoin="round"
                    />
                    <Line
                      points={arrowPoints}
                      stroke="blue"
                      strokeWidth={2}
                      closed
                      fill="blue"
                    />
                  </React.Fragment>
                );
              })}
            </Layer>
          </Stage>
        </div>
      ))}
    </div>
  );
};

export default RenderPath;
