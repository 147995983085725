
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import React from 'react';
import Login from "./pages/login_signup"

import AdminFillOut from './pages/AdminFillOut';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="adminFillOut" element={<AdminFillOut />} />

      </Routes>
    </Router>
  );
}

export default App;
