import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Modal from '../components/modal';

const { backEndBaseURL } = require('../config');


function formatEdit(response){
  const edit = Array.from(response);
  console.log('this is the: ', response);
  const array = edit.map(item => ({
    value: `${item.classId}`,
    label: `${item.className}`
  }));
  console.log(array);
  return array;
}
async function retrieveData(schoolId, teacherId) {
  console.log(`${backEndBaseURL}/v1/class/${schoolId}`);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/class?schoolID=${schoolId}&teacherID=${teacherId}`, {
      method: 'GET'
    });
      if (!result.ok) { // Check if the response status is not OK
          throw new Error(`HTTP error! status: ${result.status}`);
      }
      var response = await result.json();
      if (response[0]) {
          const output = formatEdit(response);
          return new Promise(function(resolve, reject){
            resolve(output);
           });
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw new Error(e);
  }
}
async function createClassFetch(className, schoolId, teacherId, yearLv){
  console.log(`${backEndBaseURL}/v1/class`, className, schoolId, teacherId);
  try {
    const result = await fetch(`${backEndBaseURL}/v1/class`, {
      method: 'POST',
      headers: { 
                'Content-Type': 'application/json'
               },
      body: JSON.stringify({
          "name": className,
          "schoolID": Number(schoolId),
          "teacherID": Number(teacherId),
          "schoolYearLevel": Number(yearLv)
        })
    });
      if (!result.ok) { // Check if response status is invalid 
          const errMsg = await result.text();
          throw new Error(`HTTP error! Status: ${result.status} \nIssue: ${errMsg}`);
      }
  } catch (e) {
      console.log('Fetch error:', e);
      throw e;
  }
}

const SearchableDropdownClass = ({input, placeholder, disabled, onChange}) => {
  const [options, setOptions] = useState([]);
  const [recentCreate, setRecentCreate] = useState(false);
  const [recentClassName, setRecentClassName] = useState("");
  const [recentYearLv, setRecentYearLv] = useState();
  const [isCreatingClass, setIsCreatingClass] = useState(false);
  const [inputChanged, setInputChanged] = useState(false); 
  const schoolId = input[0];
  const teacherId = input[1]; 

  const fetchData = async () => {
    if(!disabled){
      try {
        const response = await retrieveData(schoolId, teacherId);
        console.log("Classes are", response);
        setOptions(response);
      } catch (error) {
        console.error('Fetch', error);
      }
    }else{
      setOptions([]);
    }
  }
  useEffect(() => {
    setInputChanged(true);
    fetchData();
  },[disabled, schoolId, teacherId]);

  async function handleCreate(inputValue){
    // -1 is used to prevent classes being added
    if(input[2] !== -1){
      setIsCreatingClass(true);
    }
  };

  // force selects the most recent creation
  function valselect(){
    if (inputChanged){
      console.log('hai');
      onChange(null);
      return null;
    }else if(recentCreate && options){
      for (var i=0; i<options.length; i++){
        if (options[i].label === recentClassName){
          onChange(options[i]);
          return options[i];
        }
      }
    }
  }

  // calls on change from parent and updates so others can be selected
  function changeEdit(selected){
    if (onChange) {
        onChange(selected);
      }
      setInputChanged(false);
      setRecentCreate(false);
    };

  return (
    <div>
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        onChange={changeEdit}
        onCreateOption={handleCreate}
        value = {valselect()}
        placeholder={placeholder}
        isDisabled={disabled} // Add this line to handle the disabled state
      />
      <Modal isOpen={isCreatingClass} close={() => setIsCreatingClass(false)}>
        <div className="info_container">
            <div className="text_above_box">Class Name</div>
            <input 
                  type="text" 
                  placeholder="ClassName" 
                  className="entry_box" 
                  value={recentClassName}
                  onChange={e => setRecentClassName(e.target.value)}
            />
            <div className="text_above_box">Year Level</div>
            <input 
                  type="number" 
                  placeholder="Last Name" 
                  className="entry_box" 
                  value={recentYearLv}
                  onChange={e => setRecentYearLv(e.target.value)}
            />
            <button onClick={async ()=> {
              console.log('creating class');
              console.log('the Class input [schoolID(s),teacherID(t), name, yearLv] s:',
                input[0], 't:',input[1], 'name:', recentClassName, "yearLv",recentYearLv);
              try{
                await createClassFetch(recentClassName, input[0], input[1], recentYearLv);
                await fetchData();
                console.log('Options are:',options);
                setInputChanged(false);
                setRecentCreate(true);
                setIsCreatingClass(false);
              }
              catch(error){
                console.log('Problem with data installment', error);
                alert(String(error));
              }}
            }>
                Create Class
            </button>
        </div>
      </Modal>
    </div>
    
  );
};

export default SearchableDropdownClass;
